import React, { useState } from "react";
import styles from "./QuotationList.module.scss";
import { connect } from "react-redux";
import Loading from "../../UI/Loading/Loading";
import EmptyState from "../../UI/EmptyState/EmptyState";
import { ReactComponent as NoResultsIcon } from "../../../assets/svg/NoResultsMagnifier.svg";
import Pagination from "../../../hooks/pagination";
import { clearProjects, filterQuotations } from "../../../store/actions";
import FilterQuotation from "../../Filters/FilterQuotations/FilterQuotation";

const QuotationList = ({
  quotations,
  setSelectedOption,
  selectedOption,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const pageNumberLimit = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);

  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    response: quotations,
  };

  const onPageChange = (pageNumber) => setCurrentPage(pageNumber);

  const onPrevClick = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit);
      setMinPageLimit(minPageLimit - pageNumberLimit);
    }
    setCurrentPage((prev) => prev - 1);
  };

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit);
      setMinPageLimit(minPageLimit + pageNumberLimit);
    }
    setCurrentPage((prev) => prev + 1);
  };


  return (
      <div className={styles.containerList}>
        <div className={styles.list}>
          <FilterQuotation 
            setIsFiltered={setIsFiltered} 
            setIsLoading={setIsLoading} 
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption} />
          {isLoading ? (
            <Loading noModal />
          ) : (
            <div className={styles.cotizacionDeMaterialesContainer}>
              {!quotations.entities || quotations.entities.length === 0 ? (
                <div className={styles.emptyState}>
                  <EmptyState title="No hay resultados para su búsqueda" icon={<NoResultsIcon width={80} height={80} />} />
                </div>
              ) : (
                <Pagination
                  {...paginationAttributes}
                  onPrevClick={onPrevClick}
                  onNextClick={onNextClick}
                  onPageChange={onPageChange}
                  isQuotation
                />
              )}
            </div>
          )}
        </div>

      </div>
  );

};

const mapStateToProps = (state) => ({
  quotations: state.profile.quotations,
});

export default connect(mapStateToProps, {
  clearProjects,
  filterQuotations,
})(QuotationList);
