import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './SendQuotePopup.module.scss';
import { resetSuccess, sendQuote } from '../../../store/actions/categoryActions';
import { motion } from 'framer-motion';
import useGetTodayDate from '../../../hooks/useGetTodayDate';
import { Send } from 'lucide-react';

const variants = {
  visible: {
    scale: 1.0,
  },
  hidden: {
    scale: 1.1,
  },
};

const SendQuotePopup = ({ onClose, sendQuote, materials, onSuccess }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [quoteData, setQuoteData] = useState({
    name: '',
    city: '',
    receiveFrom: '',
    receiveTo: '',
    currency: 'ARS',
    description: '',
    jurisdiccion: null,
    zone: null,
  });

  const jurisdictionArray = [
    { id: 1, name: "Buenos Aires" },
    { id: 2, name: "Catamarca" },
    { id: 3, name: "Chaco" },
    { id: 4, name: "Chubut" },
    { id: 5, name: "Ciudad Autónoma De Buenos Aires" },
    { id: 6, name: "Córdoba" },
    { id: 7, name: "Corrientes" },
    { id: 8, name: "Entre Ríos" },
    { id: 9, name: "Formosa" },
    { id: 10, name: "Jujuy" },
    { id: 11, name: "La Pampa" },
    { id: 12, name: "La Rioja" },
    { id: 13, name: "Mendoza" },
    { id: 14, name: "Misiones" },
    { id: 15, name: "Neuquén" },
    { id: 16, name: "Río Negro" },
    { id: 17, name: "Salta" },
    { id: 18, name: "San Juan" },
    { id: 19, name: "San Luis" },
    { id: 20, name: "Santa Cruz" },
    { id: 21, name: "Santa Fe" },
    { id: 22, name: "Santiago del Estero" },
    { id: 23, name: "Tierra del Fuego, Antártida e Isla del Atlántico Sur" },
    { id: 24, name: "Tucumán" },
  ];

  const todayDate = useGetTodayDate();
  const formRef = useRef(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuoteData(prev => ({
      ...prev,
      [name]: name === 'jurisdiccion' ? Number(value) : value,
    }));
  };

  const convertDateToISOFormat = (date) => {
    const [dd, mm, yyyy] = date.split('/');
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendQuote(quoteData, materials, navigate);
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error al enviar la cotización:", error);
    }
  };

  useEffect(() => {
    const isValid = quoteData.name && quoteData.city && quoteData.jurisdiccion && quoteData.receiveFrom && quoteData.receiveTo && quoteData.description;
    setIsButtonDisabled(!isValid);
  }, [quoteData]);

  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      className={styles.popupOverlay}>
      <div className={styles.popup}>
        <div className={styles.popup_header}>
          <h2 className={styles.popup_header_title}>Enviar para cotizar</h2>
          <button type="button" onClick={onClose} className={styles.popup_header_cancelBtn}>Cancelar</button>
        </div>
        <form className={styles.popup_form} onSubmit={handleSubmit} ref={formRef}>
          <div className={styles.formGroup}>
            <label htmlFor="name">Nombre de la Cotización</label>
            <input
              type="text"
              id="name"
              name="name"
              value={quoteData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="jurisdiccion">Ubicación</label>
            <select
              id="jurisdiccion"
              name="jurisdiccion"
              value={quoteData.jurisdiccion || ''}
              onChange={handleChange}
              onClick={(e) => e.stopPropagation()}
              required
            >
              <option value="" disabled>Seleccionar una ubicación</option>
              {jurisdictionArray.map((jurisdiction) => (
                <option value={jurisdiction.id} key={jurisdiction.id}>{jurisdiction.name}</option>
              ))}
            </select>
          </div>
          <div className={styles.formGroup} required>
            <label htmlFor="city">Ciudad</label>
            <input
              type="text"
              id="city"
              name="city"
              value={quoteData.city}
              onChange={handleChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <div className={styles.datePickerWrapper}>
              <label htmlFor="receiveFrom">Recibir desde</label>
              <input
                type="date"
                id="receiveFrom"
                name="receiveFrom"
                value={quoteData.receiveFrom}
                onChange={(e) => {
                  handleChange(e);
                  if (quoteData.receiveTo && new Date(e.target.value) > new Date(quoteData.receiveTo)) {
                    setQuoteData(prev => ({ ...prev, receiveTo: '' })); 
                  }
                }}
                max={quoteData.receiveTo || undefined}
                min={convertDateToISOFormat(todayDate)} 
                required
                className={styles.datePicker}
              />
            </div>
            <div className={styles.datePickerWrapper}>
              <label htmlFor="receiveTo">Hasta</label>
              <input
                type="date"
                id="receiveTo"
                name="receiveTo"
                value={quoteData.receiveTo}
                onChange={(e) => {
                  handleChange(e);
                  if (quoteData.receiveFrom && new Date(e.target.value) < new Date(quoteData.receiveFrom)) {
                    setQuoteData(prev => ({ ...prev, receiveFrom: '' })); 
                  }
                }}
                min={quoteData.receiveFrom || convertDateToISOFormat(todayDate)} 
                required
                className={styles.datePicker}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="currency">Moneda</label>
            <select
              id="currency"
              name="currency"
              value={quoteData.currency}
              onChange={handleChange}
              required
            >
              <option value="ARS">Pesos</option>
              <option value="USD">Dólares</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="description">Descripción</label>
            <textarea
              id="description"
              name="description"
              value={quoteData.description}
              onChange={handleChange}
              rows="4"
            ></textarea>
          </div>
        </form>
        <div className={styles.buttonGroup}>
          <button disabled={isButtonDisabled} type="submit" className={styles.sendBtn} onClick={handleSubmit}>
            <Send size={16} />
            {isButtonDisabled ? 'Completar todos los campos' : 'Publicar en edify'}
            </button>
        </div>
      </div>
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    success: state.category.success,
    error: state.category.error,
    loading: state.category.loading,
  };
};

export default connect(mapStateToProps, { sendQuote, resetSuccess })(SendQuotePopup);


