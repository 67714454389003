import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import SolicitudeWrapper from "../../components/Solicitudes/SolicitudesWrapper/SolicitudesWrapper";
import Botonera from "../../components/Botonera/Botonera";
import { openFeedTextModal, viewAccessRequest } from "../../store/actions";


const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`;


const Solicitudes = ({ viewAccessRequest }) => {
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);


  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };

  useEffect(() => {
    handleResize();
    viewAccessRequest();
  }, []);


  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <Container>
      <Wrapper>
      {ocultarEnMobile ? <Botonera mobileStylesFromParent={mobileStylesFromParent} /> :
            null}
        <SolicitudeWrapper />
      </Wrapper>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    solicitudesList: state.dashboard.solicitudesList,
  };
};

export default connect(mapStateToProps, {
 openFeedTextModal,
 viewAccessRequest,
})(Solicitudes);

