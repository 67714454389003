import React from "react";
import styles from "./ListedWorkers.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import useGetTodayDate from "../../../hooks/useGetTodayDate";
import MapsLoading from "../../UI/Loading/MapLoading";

const ListedWorkers = ({ workers, loading }) => {

    const todayDate = useGetTodayDate();

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        return new Date(`${year}-${month}-${day}`);
    };

    const formattedTodayDate = parseDate(todayDate);

    const formatEmploymentEndDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const handleMail = (email) => {
        const isGmail = email.includes("@gmail.com");
        const mailtoLink = isGmail ? `https://mail.google.com/mail/?view=cm&fs=1&to=${email}` : `mailto:${email}`;
        window.open(mailtoLink, "_blank");
    }

    const handlePhone = (phone) => {
        const whatsappLink = `https://wa.me/${phone.replace(/\D/g, '')}`;
        window.open(whatsappLink, "_blank");
    }

    return (
        <AnimatePresence exitBeforeEnter>
            {loading && <MapsLoading />}
            <motion.table
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={styles.workersTable}>
                <thead className={styles.tableHeader}>
                    <tr>
                        <th className={styles.headerCell}>Nombre Completo</th>
                        <th className={styles.headerCell}>Teléfono</th>
                        <th className={styles.headerCell}>Email</th>
                        <th className={styles.headerCell}>Lugar</th>
                        <th className={styles.headerCell}>Rubro</th>
                        <th className={styles.headerCell}>Libreta de Desempleo</th>
                        <th className={styles.headerCell}>Disponibilidad</th>
                    </tr>
                </thead>
                <tbody className={styles.tableBody}>
                    {workers.map(worker => (
                        <motion.tr
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            key={worker.id}
                            className={styles.workerRow}
                        >
                            <td className={styles.bodyCell}>{worker.name} {worker.last_name}</td>
                            <td className={styles.bodyCell}>
                                <button
                                    title="Enviar mensaje de WhatsApp"
                                    className={styles.linkButton}
                                    onClick={() => handlePhone(worker.phone)}
                                >
                                    {worker.phone}
                                </button>
                            </td>
                            <td className={styles.bodyCell}>
                                <button
                                    title={`Enviar email a ${worker.email}`}
                                    className={styles.linkButton}
                                    onClick={() => handleMail(worker.email)}
                                >
                                    {worker.email}
                                </button>
                            </td>
                            <td className={styles.bodyCell}>
                                {worker?.jobBoardZones?.map(zone => zone.city.replace(", Argentina", "")).join(", ")}
                            </td>
                            <td className={styles.bodyCell}>
                                {worker?.skill?.map(skill => skill?.skill?.name).join(", ")}
                            </td>
                            <td className={styles.bodyCell}>{worker?.unemployment_fund ? "Sí" : "No"}</td>
                            <td className={styles.bodyCell}>
                                {new Date(worker?.employment_end_date) < formattedTodayDate ? (
                                    <div className={styles.bodyCell_isAvailable}>
                                        <div className={styles.bodyCell_isAvailable_icon}></div>
                                        <p>Inmediata</p>
                                    </div>
                                ) : (
                                    <div className={styles.bodyCell_isAvailable}>
                                        <div className={styles.bodyCell_isAvailable_iconNo}></div>
                                        <p>A partir del {formatEmploymentEndDate(worker?.employment_end_date)}</p>
                                    </div>
                                )}
                            </td>
                        </motion.tr>
                    ))}
                </tbody>
            </motion.table>
        </AnimatePresence>
    );
}

export default ListedWorkers;

