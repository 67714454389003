import React, { useState } from "react";
import styles from "./RegisteredUserItem.module.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as StarEmpty } from "../../assets/svg/emptyStarSvg.svg";
import { ReactComponent as StarFull } from "../../assets/svg/starFullSvg.svg";
import { ReactComponent as StarHalf } from "../../assets/svg/halfStarSvg.svg";
import { ReactComponent as NoImage } from "../../assets/svg/noImageIcon.svg";
import { Briefcase, ChevronDown, ChevronUp, ImageOff, MapPin, Star } from "lucide-react";
import { connect } from "react-redux";
import { openSendContactNotificationModal } from "../../store/actions";

const RegisteredUserItem = ({ user, openSendContactNotificationModal, stars, portfolio, profession }) => {
    const [imageError, setImageError] = useState(false);
    const navigate = useNavigate();
    const [showAllTags, setShowAllTags] = useState(false);
    const tags = user.skill.map((skill) => skill.skill.name);
    const projectCount = user.portfolio.length;
    const userLocation = user.zone;
    
  const maxVisibleTags = 2;
  const visibleTags = showAllTags ? tags : tags.slice(0, maxVisibleTags);

    const userFullName = user?.type === "COMPANY" ? user?.razon_social : user?.name + " " + user?.last_name;

    const handleRedirectToUser = () => {
        navigate(`/profesionales/${user?.id}/${userFullName}`);
    }

    const getInitials = () => {
        if (user?.type === "PERSON") {
            const firstNameInitial = user?.name?.charAt(0) || "";
            const lastNameInitial = user?.last_name?.charAt(0) || "";
            return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
        }
        if (user?.type === "COMPANY") {
            return user?.razon_social?.charAt(0)?.toUpperCase() || "";
        }
        return null;
    };

    const profileImage = user?.image_profile || null;
    const initials = getInitials();

    let starsArray = [];
    for (let i = 0; i < 5; i++) {
        if (i < stars) {
            starsArray.push(<StarFull key={i} width={10} height={10} />);
        } else if (i === stars && stars % 1 !== 0) {
            starsArray.push(<StarHalf key={i} width={10} height={10} />);
        } else {
            starsArray.push(<StarEmpty key={i} width={10} height={10} />);
        }
    }

    let portfolioImages = [];
    if (portfolio && portfolio.length > 0) {
        for (let i = 0; i < Math.min(portfolio.length, 3); i++) {
            const portfolioItem = portfolio[i];
            if (portfolioItem.portfolio_images && portfolioItem.portfolio_images.length > 0) {
                const firstImage = portfolioItem.portfolio_images[0].image;
                portfolioImages.push(
                    <img key={i} src={firstImage} alt={`Portfolio ${i + 1}`} className={styles.portfolioImage} />
                );
            }

        }

    }

    if (portfolioImages.length === 0) {
        portfolioImages.push(
            <NoImage key="noImage" width={100} height={100} />
        );
    }

    const handleContact = (e) => {
        e.stopPropagation();
        openSendContactNotificationModal(user)
    }

    return (
        <div className={styles.card} >
      <div className={styles.header}>
        <h3 className={styles.category}>{profession}</h3>
      </div>
      <div className={styles.content}>
        <div className={styles.profileInfo}>
          <img src={profileImage} alt={userFullName} className={styles.avatar} />
          <div>
            <h4 className={styles.name}>{userFullName}</h4>
            <div className={styles.rating}>
              {[...Array(5)].map((_, i) => (
                <Star
                  key={i}
                  className={`${styles.star} ${i < stars ? styles.filled : styles.empty}`}
                />
              ))}
              <span className={styles.ratingValue}>({stars.toFixed(1)})</span>
            </div>
          </div>
        </div>
        <div className={styles.infoItem}>
            {userLocation? (
                <>   
              <MapPin className={styles.icon} />
              <span>{userLocation}</span> 
                </> 
            ): null}
            
         
        </div>
        <div className={styles.infoItem}>
          <Briefcase className={styles.icon} />
          <span>{projectCount} proyecto{projectCount !== 1 ? 's' : ''} completado{projectCount !== 1 ? 's' : ''}</span>
        </div>
        <div className={styles.tags}>
          {visibleTags.map((tag, index) => (
            <span key={index} className={styles.tag}>
              {tag}
            </span>
          ))}
          {tags.length > maxVisibleTags && (
          <button className={styles.showMoreButton} >
            {
              <>
                 ({tags.length - maxVisibleTags })
              </>
            }
          </button>
        )}
        </div>
        
        <div className={styles.buttons}>
           
       <p className={styles.button} onClick={() => handleRedirectToUser()}>Ver perfil</p> 
       <button className={styles.contactButton} onClick={handleContact}>Contactar</button>
        </div>
        
      </div>
    </div>
    );
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, { openSendContactNotificationModal })(RegisteredUserItem);