import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import styled from "styled-components";
import HeaderNav from "../Nav/HeaderNav";
import { useLocation } from "react-router-dom";

const Container = styled.main`
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: #f7f7f7;
  box-sizing: border-box;
  transition: padding-left 0.5s ease-in-out;
  margin-top: 60px;
  @media (max-width: 768px) {
    padding-left: 0px;
    margin-top: 0px;
  }
  @media (max-width: 480px) {
    padding-left: 0px;
    margin-top: 0px;
  }
`;

const ContainerBig = styled.main`
  display: flex;
  position: relative;
  justify-content: center;
`;

const Content = styled.main`
  width: 100%;
  height: 100%;
  min-height: ${(props) => (props.isDashboard ? "85vh" : "calc(100vh - 60px)")};
  margin: 0 auto;
  @media (max-width: 480px) {
    padding: 0;
    height: 100%;
  }
`;

const Layout = ({ children }) => {
  const [ocultarEnMobile, setOcultarEnMobile] = useState(true);
  const [reducedNav, setReducedNav] = useState(false);
  const location = useLocation();

  const isDashboard = location.pathname === "/dashboard";

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 768);
  };

  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <ContainerBig>
      {!ocultarEnMobile && (
          <HeaderNav />
      )}
      <Container reducedNav={reducedNav}>
        <Content reducedNav={reducedNav}>{children}
        </Content>
        {isDashboard && <Footer />}
      </Container>
    </ContainerBig>
  );
};

export default Layout;
