import React, { useState, useEffect } from "react";
import styles from "./PrivateQuotationCard.module.scss";
import usePremiumCheck from "../../hooks/usePremiumCheck";
import { CalendarDays, MapPin, Pickaxe, DollarSign, Building2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

const PrivateQuotationCard = ({ item }) => {
  const [randomImage, setRandomImage] = useState(null);

    const navigate = useNavigate();

  useEffect(() => {
    getRandomImage();
  }, []);

  const handleOnClick = () => {
    navigate(`/cotizaciondemateriales/${item?.id}`);
  }

  const premiumCheck = usePremiumCheck();

  let {
    name,
    zone,
    startDate,
    currency,
    organismo,
    jurisdiccion,
    obra_type,
    user,
    endDate,
    subquotation,
  } = item;

 console.log(item);

  const formatDate = (date) => {
    const utcDate = new Date(date);
    const year = utcDate.getUTCFullYear();
    const month = utcDate.getUTCMonth() + 1;
    const day = utcDate.getUTCDate();
    return `${day}/${month}/${year}`;
  };


  const constructionImages = [
    "https://images.pexels.com/photos/3990359/pexels-photo-3990359.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/439416/pexels-photo-439416.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/93400/pexels-photo-93400.jpeg?auto=compress&cs=tinysrgb&w=600", 
    "https://images.pexels.com/photos/1078884/pexels-photo-1078884.jpeg?auto=compress&cs=tinysrgb&w=600", 
    "https://images.pexels.com/photos/2138126/pexels-photo-2138126.jpeg?auto=compress&cs=tinysrgb&w=600", 
    "https://images.pexels.com/photos/1117452/pexels-photo-1117452.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/224924/pexels-photo-224924.jpeg?auto=compress&cs=tinysrgb&w=600", 
    "https://images.pexels.com/photos/7931/pexels-photo-7931.jpg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/1463917/pexels-photo-1463917.jpeg?auto=compress&cs=tinysrgb&w=600" 
  ];
  
// Función para obtener una imagen aleatoria
const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * constructionImages.length);
    return setRandomImage(constructionImages[randomIndex]);
}

const userNameToShow = item?.user?.type === "COMPANY" ? item?.user.razon_social : item?.user?.name + " " + item?.user?.last_name;

const extractCategoryNamesFromSubquotation = (subquotation) => {
  return subquotation
    .flatMap((item) => item.subQuotation_materials || []) 
    .map((material) => material.material?.category?.name) 
    .filter(Boolean);
};

// Obtener los resultados
const categoryNames = extractCategoryNamesFromSubquotation(subquotation);
console.log(categoryNames);

  return (
    <div className={styles.licitacionesItemCard}>
      <div className={styles.licitacionesItemCard_backGround}>
          <img src={randomImage} alt="" className={styles.licitacionesItemCard_backGround_img} />
        <div className={styles.licitacionesItemCard_backGround_data}>
          <img src={user?.image_profile} alt="Foto de perfil" className={styles.licitacionesItemCard_backGround_data_logo} />
          <div className={styles.licitacionesItemCard_backGround_data_info}>
            <h4 className={styles.licitacionesItemCard_backGround_data_info_name}>
              {name}
            </h4>
            <p>{userNameToShow}</p>
          </div>
        </div>
      </div>
      <div className={styles.licitacionesItemCard_content}>
        <div className={styles.licitacionesItemCard_content_zone}>
          <MapPin size={20} style={{ minWidth: "2rem" }} />
          {jurisdiccion?.name} - {zone}
        </div>
        {organismo && (
        <div className={styles.licitacionesItemCard_content_zone}>
          <Building2 size={20} style={{ minWidth: "2rem" }} />
          {organismo.name}
        </div>
        )}
        <div className={styles.licitacionesItemCard_content_workType}>
          <Pickaxe size={20} style={{ minWidth: "2rem" }} />
          <div className={styles.licitacionesItemCard_content_workType_type}>
          {categoryNames?.map((category, idx) => (
            <span key={idx} className={styles.licitacionesItemCard_content_workType_category}>
              {category}
            </span>
          ))}
          </div>
        </div>
        <div className={styles.licitacionesItemCard_content_dates}>
          <CalendarDays size={20} style={{ minWidth: "2rem" }} />
          <div className={styles.licitacionesItemCard_content_dates_dates}>
          <span className={styles.licitacionesItemCard_content_dates_dateContainer}>
            {formatDate(startDate)} 
          </span> - <span className={styles.licitacionesItemCard_content_dates_date} >
            {formatDate(endDate)}
          </span>
          </div>
          
        </div>
        <div className={styles.licitacionesItemCard_content_budget}>
            <DollarSign size={20} style={{ minWidth: "2rem" }} />
            <span className={styles.licitacionesItemCard_content_budget_price}>
              {currency} 
            </span>
          </div>
      </div>
      <div className={styles.licitacionesItemCard_content_button}>
        <button className={styles.licitacionesItemCard_content_button_btn} onClick={premiumCheck(handleOnClick)}>Cotizar</button>
      </div>
    </div>

  );  
}

export default PrivateQuotationCard;