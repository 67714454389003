import React, { useState } from "react";
import styles from "./SummaryPanel.module.scss";
import { connect } from "react-redux";
import useGetTodayDate from "../../../hooks/useGetTodayDate";
import { useNavigate } from "react-router-dom";

const SummaryPanel = ({ totals, userData, setEndDate, endDate, handleSendQuotation, id }) => {
  const [isIvaChecked, setIsIvaChecked] = useState(false);

  const navigate = useNavigate();

  const fullName = userData.type === "COMPANY"
    ? userData.razon_social
    : `${userData.name} ${userData.last_name}`;

  const calculateIva = (subtotal) => Math.round(subtotal * 0.21);

  const total = isIvaChecked
    ? Math.round(calculateIva(totals.subtotal) + totals.subtotal)
    : Math.round(totals.subtotal);

  const todayDate = useGetTodayDate();

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    if (selectedDate) {
      setEndDate(selectedDate);
    }
  };

  const convertDateToISOFormat = (date) => {
    const [dd, mm, yyyy] = date.split('/');
    return `${yyyy}-${mm}-${dd}`;
  };

  const navigateToQuotation = (id) => {
    navigate(`/cotizaciones/${id}`);
  };

  return (
    <div className={styles.cotizador}>
      <div className={styles.container}>
        <h2 className={styles.title}>COTIZADOR</h2>

        <div className={styles.user}>
          <img className={styles.avatar} src={userData.image_profile} alt="user" />
          <div className={styles.name}>{fullName}</div>
        </div>

        <div className={styles.details}>
          <div className={styles.detailRow}>
            <div>Válido hasta:</div>
            <div className={styles.date}>
              <input
                type="date"
                value={endDate}
                onChange={handleDateChange}
                className={styles.dateInput}
                onMouseDown={(e) => e.preventDefault()}
                onFocus={(e) => e.target.showPicker()}
                min={convertDateToISOFormat(todayDate)}
              />
            </div>
          </div>

          <div className={styles.checkboxRow}>
            <div>IVA</div>
            <div>
              <input
                type="checkbox"
                checked={isIvaChecked}
                onChange={(event) => setIsIvaChecked(event.target.checked)}
              />
            </div>
          </div>

          <div>
            <div className={styles.detailRow}>
              <div>Subtotal</div>
              <div>{totals.subtotal}</div>
            </div>
            <div className={styles.detailRow}>
              <div>IVA*</div>
              <div>{isIvaChecked ? calculateIva(totals.subtotal) : 0}</div>
            </div>
            <div className={`${styles.detailRow} ${styles.detailRow_total}`}>
              <div>TOTAL</div>
              <div>{total}</div>
            </div>
          </div>
        </div>

        <div className={styles.buttons}>
          <button className={`${styles.button} ${styles.button_cancel}`}
            onClick={() => navigateToQuotation(id)}>
            Cancelar
          </button>
          <button
            className={`${styles.button} ${styles.button_send}`}
            onClick={handleSendQuotation}
            disabled={!endDate}
          >
            Enviar
          </button>
        </div>
      </div>

      <div className={styles.note}>
        *Impuesto al Valor Adquirido (21%)
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.general.userData,
});

export default connect(mapStateToProps)(SummaryPanel);
