import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { connect } from 'react-redux';
import { getQuotationById, getMaterialsBySubquotationId, getSubQuotationsPostulation, deleteQuotation, openMainModal, PauseQuotation } from '../../../store/actions/profileActions';
import styles from './QuotationDetails.module.scss';
import QuotationForm from '../CotizadorDeMateriales/Quotationform';
import MaterialTable from '../CotizadorDeMateriales/MaterialTable';
import { useNavigate, useParams } from 'react-router-dom';
import NewNav from '../../Nav/NewNav';
import Botonera from '../../Botonera/Botonera';
import { Calculator, Inbox, Pause, Pencil, Play, Trash2 } from 'lucide-react';
import Tooltip from '../../UI/ToolTip/ToolTip';
const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  padding-right: 8%;
  padding-left: 8%;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
  `;

const QuotationDetails = ({
  quotationData,
  materialsBySubquotation,
  getQuotationById,
  getMaterialsBySubquotationId,
  loading,
  getSubQuotationsPostulation,
  subQuotationsPostulations,
  deleteQuotation,
  openMainModal,
  PauseQuotation,
  userData
}) => {
  const [materials, setMaterials] = useState([]);
  const [receivedQuotes, setReceivedQuotes] = useState([]);
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [showToolTip, setShowToolTip] = useState({
    edit: { show: false, text: "Editar" },
    pause: { show: false, text: "Pausar" },
    reactivate: { show: false, text: "Reactivar" },
    delete: { show: false, text: "Eliminar" },
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (id && (!quotationData || quotationData.id !== id || shouldRefresh)) {
      getQuotationById(id, false, null);
      setShouldRefresh(false); 
    }
  }, [id, quotationData?.id, shouldRefresh, getQuotationById]);

  useEffect(() => {
    const subquotationId = quotationData?.subquotation?.[0]?.id;

    if (subquotationId) {
      getMaterialsBySubquotationId(subquotationId);
      getSubQuotationsPostulation(subquotationId);
    }
  }, [quotationData?.subquotation, getMaterialsBySubquotationId, getSubQuotationsPostulation]);

  useEffect(() => {
    if (materialsBySubquotation) {
      const transformedMaterials = Object.values(materialsBySubquotation).flat();
      setMaterials(transformedMaterials.map((category) => ({
        category_id: category.category_id,
        name: category.name,
        materials: category.materials,
      })));
    }

    if (subQuotationsPostulations) {
      setReceivedQuotes(subQuotationsPostulations);
    }
  }, [materialsBySubquotation, subQuotationsPostulations]);


  const handleEditClick = () => {
    navigate(`/cotizaciondemateriales/edit/${id}`);
  };

  const handleDeleteQuotation = (id) => {
    openMainModal({
      title: "¿Estás seguro de querer eliminar la cotización?",
      body: "Si la eliminas no podrás recuperarla.",
      isSuccess: false,
      confirmHandler: () => {
        deleteQuotation(id, navigate);
      }
    });
  };

  const handleToggleQuotation = (id, isActive) => {
    const action = isActive ? "reactivar" : "pausar";
    const title = `¿Estás seguro de querer ${action} la cotización?`;
    const body = isActive
      ? "Si reactivas la cotización, estará disponible para los usuarios."
      : "Si pausas la cotización, no podrá ser vista por los usuarios de Edify.";
  
    openMainModal({
      title,
      body,
      isSuccess: false,
      confirmHandler: async () => {
        await PauseQuotation(id, isActive); // Realiza la acción
        setShouldRefresh(true); // Activa la actualización de datos
      },
    });
  };

  const navigateToQuotationForm = (id) => {
    navigate(`/cotizaciones/postulante/${id}`);
  };

  const handleDownload = (fileUrl) => {
    if (!fileUrl) {
      alert("El archivo no está disponible.");
      return;
    }
    window.open(fileUrl, '_blank');
  };

  const handleTooltip = (button) => {
    setShowToolTip((prevState) => ({
      ...prevState,
      [button]: { ...prevState[button], show: !prevState[button].show },
    }));
  };

  if (loading) return <p>Cargando...</p>;

  const isPaused = !quotationData.quotation?.active;

  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}

      <div className={styles['quotation-details']}>

        <div className={styles['main-content']}>
          <div className=
            {`${styles['left-section']} ${isPaused ? styles['disabled'] : ''}`}>
            <div className={styles['general-info']}>
              <QuotationForm quotation={quotationData} isPaused={isPaused} />
            </div>
            {/* <div className={styles['materials-section']}> */}
              <MaterialTable materials={materials} isEditable={false} />

           {/*  </div> */}
            <p className={styles['iva-notice']}>*Impuesto al Valor Agregado (21%)</p>
          </div>


          <div className={styles['right-section']}>
            {userData?.id === quotationData?.user?.id || userData?.id === quotationData?.quotation?.user?.id ? (
              <>
                <div className={styles['actions']}>
                  <button className={styles['edit']} onClick={handleEditClick}
                    onMouseEnter={() => handleTooltip("edit")}
                    onMouseLeave={() => handleTooltip("edit")}>
                    <Pencil size={20} />
                    {showToolTip.edit.show && <Tooltip text={showToolTip.edit.text} />}
                  </button>

                  <button
                    className={styles[isPaused ? 'reactivate' : 'pause']}
                    onClick={() => handleToggleQuotation(id, isPaused)}
                    onMouseEnter={() => handleTooltip(isPaused ? "reactivate" : "pause")}
                    onMouseLeave={() => handleTooltip(isPaused ? "reactivate" : "pause")}>
                    {isPaused ? <Play size={20} /> : <Pause size={20} color='white' />}
                    {showToolTip[isPaused ? "reactivate" : "pause"]?.show && (
                      <Tooltip
                        text={isPaused ? "Reanudar" : "Pausar"}
                      />
                    )}
                  </button>
                  <button className={styles['delete']} onClick={() => handleDeleteQuotation(id)}
                    onMouseEnter={() => handleTooltip("delete")}
                    onMouseLeave={() => handleTooltip("delete")}>
                    <Trash2 size={20} />
                    {showToolTip.delete.show && <Tooltip text={showToolTip.delete.text} />}
                  </button>
                </div>

                <section className={styles['received-quotes']}>
                  <div className={styles['received-title']}>RECIBIDOS</div>
                  {receivedQuotes.length === 0 ? (
                    <div className={styles['empty-state']}>
                      <Inbox size={48} color="#ccc" />
                      <p>No hay cotizaciones recibidas.</p>
                    </div>
                  ) : (
                    receivedQuotes.map((quote, idx) => (
                      <div
                        key={idx}
                        className={styles['received-quote']}
                        onClick={() => handleDownload(quote.file)}
                      >
                        <div className={styles['quote-content']}>
                          <p>
                            <strong>Creador:</strong> {quote.user.type === "COMPANY" ? quote.user.razon_social : `${quote.user.name} ${quote.user.last_name}`}
                          </p>
                          <p>
                            <strong>Válido hasta:</strong> {quote.end_date}
                          </p>
                        </div>
                        <div className={styles['arrow']}>{'>'}</div>
                      </div>
                    ))
                  )}
                </section>
              </>
            ) : (
              <div className={styles['actions']}>
                <button
                  className={styles['quote-button']} onClick={() => navigateToQuotationForm(id)}>
                  <Calculator size={22} style={{ marginRight: "10px" }} />
                  Cotizar
                </button>
              </div>

            )}
          </div>
        </div>
      </div>
    </Container>
  );
};


const mapStateToProps = (state) => ({
  quotationData: state.profile.quotation,
  materialsBySubquotation: state.profile.materialsBySubquotation,
  loading: state.profile.loading,
  error: state.profile.error,
  userData: state.general.userData,
  subQuotationsPostulations: state.profile.subQuotationsPostulations,
});

const mapDispatchToProps = {
  getQuotationById,
  getMaterialsBySubquotationId,
  getSubQuotationsPostulation,
  deleteQuotation,
  openMainModal,
  PauseQuotation
};

export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetails);
