import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faSearch, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ClipboardList, Send } from 'lucide-react';
import AddCustomMaterialPopup from './AddCustomMaterialPopup';
import SendQuotePopup from './SendQuotePopup';
import styles from './CalculadoraDeMateriales.module.scss';
import DownloadPDFButton from './DownloadPdfButton';
import { getCategoriesWithMaterials } from '../../../store/actions/categoryActions';
import { editSubquotationMaterials, getMaterialsBySubquotationId, openMainModal } from '../../../store/actions/profileActions';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import RegisteredUserProfileLoader from '../../RegisteredUsers/RegisteredUsersSkeleton/RegisteredUserProfileLoader';

const ulVariants = {
  hidden: {
    opacity: 0,
    height: 0,
  },
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      opacity: {
        duration: 0.8,
      },
      height: {
        duration: 0.3,
      },
    },
  },
};


const CalculadoraDeMateriales = ({
  getCategoriesWithMaterials,
  categoriesWithMaterials,
  quotationData,
  materialsBySubquotation,
  openMainModal,
  getMaterialsBySubquotationId,
  editSubquotationMaterials }) => {

  const { id } = useParams();

  const [categories, setCategories] = useState(categoriesWithMaterials);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState(['electrical', 'plumbing']);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [showAddCustomPopup, setShowAddCustomPopup] = useState(false);
  const [showSendQuotePopup, setShowSendQuotePopup] = useState(false);
  const [isSyncingMaterials, setIsSyncingMaterials] = useState(true);
  const [originalMaterials, setOriginalMaterials] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(!!id);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getCategoriesWithMaterials();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setCategories(categoriesWithMaterials);
  }, [categoriesWithMaterials]);

  useEffect(() => {
    setIsEditMode(!!id);
    setSelectedMaterials([]);
  }, [id]);



  useEffect(() => {
    if (isEditMode && quotationData && isSyncingMaterials) {
      const fetchMaterials = async () => {
        try {
          setIsLoading(true);
          await getMaterialsBySubquotationId(quotationData?.subquotation[0]?.id);

          if (!materialsBySubquotation) {
            console.warn("No hay materiales para las subcotizaciones");
            return;
          }

          const subCotizaciones = Object.values(materialsBySubquotation);
          const formattedMaterials = subCotizaciones.flatMap((subCotizacion) =>
            subCotizacion.flatMap((category) =>
              (category.materials || []).map((material) => ({
                id: material.id,
                name: material.name,
                unit: material.unit || 'N/A',
                quantity: material.amount || 1,
                category: category.name || 'Sin Categoría',
              }))
            )
          );

          setSelectedMaterials(formattedMaterials);
        } catch (error) {
          console.error("Error al obtener materiales:", error);
        } finally {
          setIsSyncingMaterials(false);
          setIsLoading(false);
        }
      };

      fetchMaterials();
    }
  }, [isEditMode, quotationData, isSyncingMaterials]);

  //Lógica para saber si hubo cambios en los materiales

  useEffect(() => {
    if (isEditMode && materialsBySubquotation) {
      const subCotizaciones = Object.values(materialsBySubquotation);
      const formattedMaterials = subCotizaciones.flatMap((subCotizacion) =>
        subCotizacion.flatMap((category) =>
          (category.materials || []).map((material) => ({
            id: material.id,
            name: material.name,
            unit: material.unit || 'N/A',
            quantity: material.amount || 1,
            category: category.name || 'Sin Categoría',
          }))
        )
      );
      setOriginalMaterials(formattedMaterials);
    }
  }, [materialsBySubquotation, isEditMode]);

  // Función para verificar si los materiales han cambiado
  const hasChanges = () => {
    if (selectedMaterials.length !== originalMaterials.length) {
      return true;
    }

    return selectedMaterials.some((material, index) => {
      const originalMaterial = originalMaterials[index];
      return (
        material.id !== originalMaterial.id ||
        Number(material.quantity) !== Number(originalMaterial.quantity)
      );
    });
  };

  const removeMaterial = (id) => {
    setSelectedMaterials((prev) => prev.filter((m) => m.id !== id));
  };

  useEffect(() => {
    if (!searchTerm) {
      setFilteredMaterials([]);
      return;
    }

    const filtered = (categories.data || []).flatMap((category) => {
      return (category.materials || [])
        .filter((material) => {
          return material.name.toLowerCase().includes(searchTerm.toLowerCase());
        })
        .map((material) => ({
          ...material,
          category: category.name,
        }));
    });

    setFilteredMaterials(filtered);
  }, [searchTerm, categories]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
  };
  const toggleMaterial = (material, categoryId) => {
    const category = categories?.data?.find((cat) => cat.id === categoryId)?.name || categoryId;

    setSelectedMaterials((prev) => {
      const existingIndex = prev.findIndex((m) => m.id === material.id);
      if (existingIndex >= 0) {
        return prev.filter((m) => m.id !== material.id);
      } else {
        return [...prev, { ...material, quantity: 1, category }];
      }
    });
    setSearchTerm('');
  };

  const updateQuantity = (id, quantity) => {
    setSelectedMaterials((prev) =>
      prev.map((m) => (m.id === id ? { ...m, quantity } : m))
    );
  };

  const addCustomMaterial = (newMaterial) => {
    if (!Array.isArray(categories.data)) {
      console.error("Categories data is not an array");
      return;
    }

    const categoryIndex = categories?.data?.findIndex((c) => c.id === newMaterial.categoryId);

    if (categoryIndex !== -1) {
      setCategories((prevCategories) => {
        const newCategories = { ...prevCategories };
        newCategories.data = [...prevCategories.data];
        return newCategories;
      });
    } else {
      setCategories((prevCategories) => ({
        ...prevCategories,
        data: [
          ...prevCategories.data,
          { id: newMaterial.categoryId, name: newMaterial.category, materials: [{ ...newMaterial, temporary: true }], jurisdiction: newMaterial.jurisdiction, currency: newMaterial.currency },
        ],
      }));
    }

    setSelectedMaterials((prevSelected) => [
      ...prevSelected,
      {
        ...newMaterial,
        category: categories?.data?.find((c) => c.id === newMaterial.categoryId)?.name || newMaterial.category,
        unit: newMaterial.unit,
        quantity: newMaterial.quantity || 1,
        temporary: true,
        currency: newMaterial.currency,
      },
    ]);

    setShowAddCustomPopup(false);
  };
  const total = selectedMaterials?.reduce((sum, m) => sum + m.price * m.quantity, 0);

  const groupedMaterials = selectedMaterials.reduce((acc, material) => {
    if (!acc[material.category]) {
      acc[material.category] = [];
    }
    acc[material.category].push(material);
    return acc;
  }, {});

  const handleSendQuoteClick = async () => {
    if (isEditMode) {
      const confirmation = await new Promise((resolve) => {
        openMainModal({
          title: "¿Estás seguro de que deseas guardar los cambios en la cotización?",
          body: "Se le notificará a los proveedores que se han realizado cambios en la cotización.",
          iconType: "warning",
          noLabel: "Cancelar",
          yesLabel: "Guardar cambios",
          confirmHandler: () => {
            setIsLoading(true);
            resolve(true);
          },
          customCloseMainModal: () => resolve(false),
        });
      });

      if (confirmation) {
        const formattedMaterials = selectedMaterials.map((material) => {
          const category = categories?.data?.find(
            (cat) => cat.name === material.category
          );
          return {
            category_id: category?.id || 0,
            material_id: material.id,
            temporary: material.temporary || false,
            amount: material.quantity,
            name: material.name,
            unit: material.unit,
          };
        });

        try {
          await editSubquotationMaterials(
            quotationData?.subquotation[0]?.id,
            formattedMaterials,
            navigate
          );
        } catch (error) {
          console.error("Error al editar los materiales:", error);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      setShowSendQuotePopup(true);
    }
  };

  const handleQuoteSuccess = () => {
    setSelectedMaterials([]);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setFilteredMaterials([]);
  };

  return (
    <>
      {isLoading ? <RegisteredUserProfileLoader /> : (
        <div className={styles.container}>
          <div className={styles.container_header}>
            <h2 className={styles.container_header_title}>
              <ClipboardList size={20} style={{ color: '#3182ce', minWidth: "2rem", minHeight: "2rem" }} />
              {isEditMode ? "Editar cotización" : "Crear cotización"}
            </h2>
            <div className={styles.container_header_buttons}>
              {!isEditMode && (
                <DownloadPDFButton materials={selectedMaterials} className={styles.container_header_download} />
              )}
              <button
                className={styles.container_header_quoteBtn}
                onClick={handleSendQuoteClick}
                disabled={isEditMode ? !hasChanges() : selectedMaterials.length === 0}
              >
                <Send size={16} style={{minHeight: "1.6rem", minWidth: "1.6rem"}} />
                {isEditMode ? "Guardar cambios" : "Enviar para cotizar"}
              </button>
            </div>
          </div>
          <div className={styles.container_body}>
            <div className={styles.container_body_categoryList}>
              <div className={styles.container_body_categoryList_searchContainer}>
                <FontAwesomeIcon icon={faSearch} style={{ fontSize: "2rem" }} />
                <input
                  type="text"
                  placeholder="Buscar material..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  className={styles.container_body_categoryList_searchContainer_input}
                />
                {searchTerm && (
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={styles.clearIcon}
                    onClick={clearSearch}
                  />
                )}
                {searchTerm && filteredMaterials.length > 0 && (
                  <ul className={styles.container_body_categoryList_searchContainer_filteredResults}>
                    {filteredMaterials.map((material) => (
                      <li className={styles.container_body_categoryList_searchContainer_filteredResults_item} key={material.id} onClick={() => toggleMaterial(material, material.category)}>
                        <span className={styles.container_body_categoryList_searchContainer_filteredResults_item_category}>{material.category}</span>
                        <span className={styles.container_body_categoryList_searchContainer_filteredResults_item_material}>{material.name}</span>
                      </li>
                    ))}
                  </ul>
                )}
                {searchTerm && filteredMaterials.length === 0 && (
                  <ul className={styles.container_body_categoryList_searchContainer_filteredResults}>
                    <li className={styles.container_body_categoryList_searchContainer_filteredResults_item}>
                      No se encontraron materiales
                    </li>
                  </ul>
                )}
              </div>
              <div className={styles.container_body_categoryList_categories}>
                {categories?.data
                  ?.slice()
                  .sort((a, b) => a?.name?.localeCompare(b.name))
                  .map((category) => (
                    <div className={styles.container_body_categoryList_title} key={category.id}>
                      <button
                        className={styles.container_body_categoryList_title_btn}
                        onClick={() => toggleCategory(category.id)}
                      >
                        {category.name}
                        <FontAwesomeIcon
                          icon={expandedCategories.includes(category.id) ? faChevronDown : faChevronRight}
                          className={styles.icon}
                        />
                      </button>
                      {expandedCategories.includes(category.id) && (
                        <motion.ul
                          variants={ulVariants}
                          initial="hidden"
                          animate="visible"
                          className={styles.container_body_categoryList_list}
                        >
                          {category?.materials?.map((material) => {
                            const isChecked = selectedMaterials.some((m) => m.id === material.id);
                            return (
                              <li
                                key={material.id}
                                className={`${styles.container_body_categoryList_list_item} ${isChecked ? styles.checkedItem : ""}`}
                                onClick={() => toggleMaterial(material, category.id)} 
                                style={{ cursor: "pointer" }}
                              >
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={(e) => e.stopPropagation()}
                                  style={{ display: "flex", cursor: "pointer" }}
                                />
                                {material.name}
                              </li>
                            );
                          })}
                        </motion.ul>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className={styles.container_tableContainer}>
              <table className={styles.container_tableContainer_table}>
                <thead className={styles.container_tableContainer_table_header}>
                  <tr className={styles.container_tableContainer_table_header_row}>
                    <th className={styles.container_tableContainer_table_header_title}>Material</th>
                    <th className={`${styles.container_tableContainer_table_header_title} ${isEditMode && styles.expandedTh}`}>Unidad</th>
                    <th className={`${styles.container_tableContainer_table_header_title} ${isEditMode && styles.expandedTh}`}>Cantidad</th>
                    {!isEditMode && (
                      <>
                        <th className={styles.container_tableContainer_table_header_title}>Precio/u<sup>*</sup></th>

                        <th className={styles.container_tableContainer_table_header_title}>Subtotal</th>
                      </>
                    )}
                    <th className={styles.container_tableContainer_table_header_title}></th>
                  </tr>
                </thead>
                <tbody className={styles.container_tableContainer_tbody} >
                  {Object.entries(groupedMaterials)?.map(([category, materials]) => (
                    <React.Fragment key={category}>
                      <tr className={styles.container_tableContainer_tbody_tr}>
                        <td className={styles.container_tableContainer_tbody_tdSubTotal}  colSpan="6">{category || category.categoryName}
                          {!isEditMode && (
                            <span className={styles.container_tableContainer_tbody_tdSubTotal_price}>
                              {materials.reduce((acc, m) => acc + m.price * m.quantity, 0).toFixed(2)}
                            </span>
                          )}
                        </td>
                      </tr>
                      {materials?.map((material, index) => (
                        <tr
                          className={`${styles.container_tableContainer_tbody_row} ${(index % 2 === 0) ? styles.rowEven : styles.rowOdd
                            }`}
                          key={material.id}
                        >
                          <td className={styles.container_tableContainer_tbody_td}>{material.name}</td>
                          <td className={`${styles.container_tableContainer_tbody_td} ${isEditMode && styles.expandedTd}`}>{material.unit}</td>
                          <td className={`${styles.container_tableContainer_tbody_td} ${isEditMode && styles.expandedTd}`}>
                            <input
                              type="number"
                              min="1"
                              className={styles.container_tableContainer_tbody_quantityInput}
                              value={material.quantity}
                              onChange={(e) =>
                                updateQuantity(material.id, parseInt(e.target.value) || material.quantity)
                              }
                            />
                          </td>
                          {!isEditMode && (
                            <>
                              <td className={styles.container_tableContainer_tbody_td}>{material.price}</td>
                              <td className={styles.container_tableContainer_tbody_td}>
                                {material.price * material.quantity}
                              </td>
                            </>)}
                          <td className={styles.container_tableContainer_tbody_td}>
                            <button
                              onClick={() => removeMaterial(material.id)}
                              className={styles.removeBtn}
                            >
                              <FontAwesomeIcon icon={faTrash} className={styles.trashIcon} />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
                <tfoot >
                  <tr className={styles.container_tableContainer_footer}>
                    <td >
                      <button className={styles.container_tableContainer_footer_addCustom} onClick={() => setShowAddCustomPopup(true)}>
                        Crear material
                      </button>
                    </td>
                    <td colSpan="4">TOTAL</td>
                    <td>{total ? total.toFixed(2) : 0}</td>
                  </tr>
                </tfoot>
              </table>
            </div>

          </div>
          {!isEditMode && (
            <div className={styles.container_disclaimer}>
              <p className={styles.container_disclaimer_text}>
                <sup>*</sup>El precio es una estimación, no representa una cotización formal.
              </p>
            </div>
          )}
          {showAddCustomPopup && (
            <AddCustomMaterialPopup
              onClose={() => setShowAddCustomPopup(false)}
              onAdd={addCustomMaterial}
              categories={categories}
            />
          )}
          {showSendQuotePopup && (
            <SendQuotePopup
              onClose={() => setShowSendQuotePopup(false)}
              onSend={handleSendQuoteClick}
              materials={selectedMaterials}
              onSuccess={handleQuoteSuccess}
            />
          )}
        </div>
      )}
    </>
  );

};

const mapStateToProps = (state) => ({
  categoriesWithMaterials: state.category.categories,
  quotationData: state.profile.quotation,
  materialsBySubquotation: state.profile.materialsBySubquotation,
});

export default connect(mapStateToProps, {
  getCategoriesWithMaterials,
  getMaterialsBySubquotationId,
  editSubquotationMaterials,
  openMainModal,
})(CalculadoraDeMateriales);


