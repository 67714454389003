import environment from "./base";
const local = "http://localhost:8083";
const baseApi = "https://edify-api-dev.edify.la";
const authApi = "https://edify-api-dev.edify.la";
const env = environment(baseApi, authApi);
export default {
  ...env,
  intervals: {
    ...env.intervals,
    logout: 300, // 5 min in seconds
  },
  isProduction: false,
  isDevelopment: true,
  env: "development",
  SENTRY_DSN: "",
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyAiCfkrXX-14nEuZW17g56EFpcnCTQJb-o",
    authDomain: "edify-test-b1d0c.firebaseapp.com",
    projectId: "edify-test-b1d0c",
    storageBucket: "edify-test-b1d0c.appspot.com",
    messagingSenderId: "909676452655",
    appId: "1:909676452655:web:023a88b64166130b30dfda",
    measurementId: "G-8TDPKE88L8",
  },
};
