import React, { useState, useEffect } from "react";
import styles from "./CotizacionDeMateriales.module.scss";
import styled from "styled-components";
import Botonera from "../../components/Botonera/Botonera";
import { motion } from "framer-motion";
import { ClipboardList, Calculator, MousePointerClickIcon, Filter, Info, Database, HardDriveDownload, MonitorUp } from "lucide-react";
import NewNav from "../../components/Nav/NewNav";
import { useNavigate } from "react-router-dom";
import QuotationList from "../../components/MarketPlace/Cotizaciones/QuotationList";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  padding-right: 8%;
  padding-left: 8%;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
  `;

const CotizacionDeMateriales = () => {
  const navigate = useNavigate();
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);
  const [showList, setShowList] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Publicas");

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleQuotationListProjectType = (projectType) => {
    setSelectedOption(projectType);
    setShowList(true);
  }

  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      {showList ? <QuotationList setSelectedOption={setSelectedOption} selectedOption={selectedOption} /> : (
        <div className={styles.generalContainer}>
          <div className={styles.container}>
            <motion.div
              className={styles.container_section}
              initial={{ x: -500 }}
              animate={{ x: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className={styles.container_section_description}>
                <div className={styles.container_section_backgroundTitle}>
                  <h2 className={styles.container_section_title}>
                    <div className={styles.container_section_title_icon}>
                      <ClipboardList size={20} />
                    </div>
                    Explora los pedidos de cotización disponibles
                  </h2>
                </div>
                <ul className={styles.container_section_description_txt}>
                  <li className={styles.container_section_description_txt_item}>
                    <Filter style={{minHeight: "2.5rem", minWidth: "2.5rem"}} />
                    Usa los filtros para encontrar las cotizaciones que mejor se ajusten a tus necesidades.</li>
                  <li className={styles.container_section_description_txt_item}>
                    <MousePointerClickIcon style={{minHeight: "2.5rem", minWidth: "2.5rem"}}/>
                    En los pedidos de usuarios de Edify, cotiza en línea seleccionando los materiales, ingresando el precio y las condiciones. ¡Así de fácil! </li>
                  <li className={styles.container_section_description_txt_item}>
                    <Info  style={{minHeight: "2.5rem", minWidth: "2.5rem"}}/>
                    Para enviar tu presupuesto, accede a la publicación y haz clic en "Cotizar".</li>
                </ul>
                <div className={styles.container_section_backdropList}>
                  <button className={styles.container_section_buttonPublic} onClick={() => handleQuotationListProjectType("Publicas")}>
                    Entidades públicas
                  </button>
                  <button className={styles.container_section_buttonPrivate} onClick={() => handleQuotationListProjectType("Privadas")}>
                    Cotizaciones de usuarios de Edify
                  </button>
                </div>
              </div>
            </motion.div>
            <motion.div
              className={styles.container_section} initial={{ x: 500 }}
              animate={{ x: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className={styles.container_section_description}>
                <div className={styles.container_section_backgroundTitleCalculator}>
                  <h2 className={styles.container_section_title}>
                    <div className={styles.container_section_title_icon}>
                      <Calculator size={20} />
                    </div>
                    Calculadora de costos de materiales
                  </h2>
                </div>
                <ul className={styles.container_section_description_txt}>
                  <li className={styles.container_section_description_txt_item}>
                    <Database style={{minHeight: "2.5rem", minWidth: "2.5rem"}}/>
                    Podés armar tu pedido consultando nuestra base de datos de materiales.</li>
                  <li className={styles.container_section_description_txt_item}>
                    <HardDriveDownload style={{minHeight: "2.5rem", minWidth: "2.5rem"}} />
                    Al finalizar, vas a poder descargar tu estimación de costos e imprimirla.</li>
                  <li className={styles.container_section_description_txt_item}>
                    <MonitorUp style={{minHeight: "2.5rem", minWidth: "2.5rem"}} />
                    Si querés una cotización real por parte de la comunidad de edify, podés publicar tu pedido de cotización.</li>
                </ul>
                <div className={styles.container_section_backdropList}>
                <button className={styles.container_section_buttonCreate} onClick={() => navigate("/cotizaciones/crear")}>
                    Crear cotización
                  </button>
                  </div>
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default CotizacionDeMateriales;
