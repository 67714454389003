import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";
import { useNavigate, useParams } from 'react-router-dom';
import QuotationForm from './Quotationform';
import SummaryPanel from './SummaryPanel';
import MaterialTable from './MaterialTable';
import styles from './Cotizador.module.scss';
import styles2 from '../Cotizaciones/QuotationDetails.module.scss';
import { getQuotationById, getMaterialsBySubquotationId, subQuotationPostulation } from '../../../store/actions/profileActions';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import NewNav from '../../Nav/NewNav';
import Botonera from '../../Botonera/Botonera';

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  padding-right: 8%;
  padding-left: 8%;

  @media (max-width: 1200px) {
    padding-right: 5%;
    padding-left: 5%;
    }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
  `;

const Cotizador = ({ quotationData, materialsBySubquotation, loading, error, getQuotationById, getMaterialsBySubquotationId, subQuotationPostulation }) => {
  const [materials, setMaterials] = useState([]);
  const [totals, setTotals] = useState({ subtotal: 0, iva: 0, total: 0 });
  const [endDate, setEndDate] = useState("");
  const [note, setNote] = useState("");
  const [ocultarEnMobile, setOcultarEnMobile] = useState(false);
  const [mobileStylesFromParent, setMobileStylesFromParent] = useState(false);

  const navigate = useNavigate();
  const { quotationId } = useParams();

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setOcultarEnMobile(windowWidth < 900);
    setMobileStylesFromParent(windowWidth < 900);
  };

  // Ejecutar handleResize al cargar el componente
  useEffect(() => {
    handleResize();
  }, []);

  // Agregar un event listener para el cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Limpiar el event listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    if (quotationId) {
      getQuotationById(quotationId, false, null);
    }
  }, [quotationId]);

  useEffect(() => {
    if (quotationData?.subquotation?.length > 0) {
      getMaterialsBySubquotationId(quotationData.subquotation[0].id);
    }
  }, [quotationData]);

  useEffect(() => {
    if (materialsBySubquotation) {
      const transformedMaterials = Object.values(materialsBySubquotation).flat();
      setMaterials(transformedMaterials.map((category) => ({
        category_id: category.category_id,
        name: category.name,
        materials: category.materials,
      })));
    }
  }, [materialsBySubquotation, quotationId]);

  const handleMaterialsChange = (updatedMaterials) => {
    setMaterials(updatedMaterials);
    calculateTotals(updatedMaterials);
  };

  const calculateTotals = (updatedMaterials) => {
    let subtotal = 0;

    updatedMaterials.forEach((category) => {
      category.materials.forEach((item) => {
        const amount = parseFloat(item.amount) || 0;
        const priceUnit = parseFloat(item.priceUnit) || 0;
        const discount = parseFloat(item.discount) || 0;
        const totalPrice = amount * priceUnit * (1 - discount / 100);
        subtotal += totalPrice;
      });
    });

    const iva = subtotal * 0.21;
    const total = subtotal + iva;

    setTotals({ subtotal, iva, total });
  };

  const generatePdf = (materials, totals) => {
    const doc = new jsPDF();


    doc.setFontSize(16);
    doc.text("Presupuesto Detallado", 14, 20);


    const headers = [["Material", "Categoría", "Cantidad", "Precio Unitario", "Descuento", "Subtotal"]];


    const data = materials.flatMap((category) =>
      category.materials.map((material) => [
        material.name,
        category.name,
        material.amount || 0,
        `$${(!isNaN(material.priceUnit) ? parseFloat(material.priceUnit) : 0).toFixed(2)}`,
        `${(parseFloat(material.discount) || 0).toFixed(2)}%`,
        `$${((parseFloat(material.amount) || 0) * (parseFloat(material.priceUnit) || 0) * (1 - (parseFloat(material.discount) || 0) / 100)).toFixed(2)}`,
      ])
    );

    autoTable(doc, {
      head: headers,
      body: data,
      startY: 30,
      theme: "grid",
      headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255], fontSize: 12 },
      bodyStyles: { fontSize: 10 },
      columnStyles: {
        2: { halign: "right" },
        3: { halign: "right" },
        4: { halign: "right" },
        5: { halign: "right" },
      },
    });


    const tableY = doc.previousAutoTable.finalY + 10;
    doc.setFontSize(12);
    doc.text("Resumen de Totales", 14, tableY);

    autoTable(doc, {
      body: [
        ["Subtotal", `$${totals.subtotal.toFixed(2)}`],
        ["IVA (21%)", `$${totals.iva.toFixed(2)}`],
        ["Total", `$${totals.total.toFixed(2)}`],
      ],
      startY: tableY + 10,
      theme: "grid",
      bodyStyles: { fontSize: 10, halign: "right" },
      columnStyles: {
        0: { halign: "left" },
      },
    });

    return new File([doc.output("blob")], "presupuesto.pdf", { type: "application/pdf" });
  };


  const handleSendQuotation = () => {
    const pdfBlob = generatePdf(materials, totals);
    subQuotationPostulation(quotationData?.subquotation[0].id, pdfBlob, note, endDate, navigate);
  };



  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container>
      {!ocultarEnMobile && <NewNav />}
      {ocultarEnMobile && <Botonera mobileStylesFromParent={mobileStylesFromParent} />}
      <div className={styles.container}>
        <div className={styles.left_column}>

          <div className={styles2['general-info']}>
            <QuotationForm quotation={quotationData} />
          </div>
          <div className={styles['materials-section']}>
            <MaterialTable materials={materials} onMaterialsChange={handleMaterialsChange} note={note} setNote={setNote} isEditable={true} />
          </div>

        </div>
        <div className={styles.right_column}>
          <SummaryPanel totals={totals} handleSendQuotation={handleSendQuotation}
            endDate={endDate}
            setEndDate={(date) => {
              setEndDate(date);
            }}
            id={quotationData.quotation.id} />
        </div>
      </div>
    </Container>
  );

}

const mapStateToProps = (state) => ({
  quotationData: state.profile.quotation,
  materialsBySubquotation: state.profile.materialsBySubquotation,
  loading: state.profile.loading,
  error: state.profile.error,
});

const mapDispatchToProps = {
  getQuotationById,
  getMaterialsBySubquotationId,
  subQuotationPostulation
};

export default connect(mapStateToProps, mapDispatchToProps)(Cotizador);
